import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router'
import styled from 'styled-components'
import { ErrorNotFound } from 'mgr/pages/default'
import SubNav from 'mgr/pages/shared/SubNav'
import { getReportingLookerItems } from 'mgr/pages/shared/utils/Reporting'
import GuestCenterImport from 'mgr/pages/single-venue/reporting/containers/GuestCenterImport'
import ResyImport from 'mgr/pages/single-venue/reporting/containers/ResyImport'
import LookerReportingLayout from 'mgr/pages/single-venue/reporting/containers/LookerReportingLayout'
import OutgoingEmails from 'mgr/pages/single-venue/reporting/containers/OutgoingEmails'
import ReportingHomepageLayout from 'mgr/pages/single-venue/reporting/containers/ReportingHomepageLayout'
import RevenueReportLayout from 'mgr/pages/single-venue/reporting/containers/RevenueReportLayout'

const ReportingContainer = styled.div`
  display: flex;
  flex: 1;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 100vh;
  overflow-y: scroll;
`

class Reporting extends Component {
  constructor(props) {
    super(props)
    this.getStaticItems = this.getStaticItems.bind(this)

    const { match, venue, venueSettings, permissions } = this.props
    const { lookerFolders } = venue
    const lookerItems = permissions.canViewReportingFull ? getReportingLookerItems(lookerFolders, match.path) : []
    const staticItems = this.getStaticItems()
    const homeItems = this.getHomeItems()

    const items = [...homeItems, ...lookerItems, ...staticItems]

    this.state = {
      items,
    }
  }

  getStaticItems() {
    const { match, venue, venueSettings, permissions } = this.props
    const { user } = window.globalInit
    const nonReactPath = `/manager/${venue.urlKey}`
    const userUrlSuffix = permissions.canViewReportingFull ? '' : `/${user.id}`
    const adminItems = [
      {
        name: 'Administrative',
        isTitle: true,
      },
    ]
    const items = [
      {
        name: 'Standard',
        isTitle: true,
      },
    ]

    if (permissions.canViewReportingFull && permissions.legacyReportsAccess.reservation_summary) {
      items.push({
        exit: true,
        link: `${nonReactPath}/reports/summary`,
        name: 'Reservation Summary',
      })
    }
    if (permissions.legacyReportsAccess.booked_by) {
      items.push({
        exit: true,
        link: `${nonReactPath}/reports/bookedby${userUrlSuffix}`,
        name: 'Booked By',
      })
    }
    if (permissions.legacyReportsAccess.sourced_by) {
      items.push({
        exit: true,
        link: `${nonReactPath}/reports/sourceby${userUrlSuffix}`,
        name: 'Source By',
      })
    }
    if (permissions.legacyReportsAccess.guestlist && venueSettings.guestlist_enabled && permissions.canViewGuestlist) {
      items.push({
        exit: true,
        link: `${nonReactPath}/reports/guestlist/bookedby${userUrlSuffix}`,
        name: 'Guest List',
      })
    }
    if (permissions.canViewReportingFull) {
      if (permissions.legacyReportsAccess.tally && venue.promoter_tally_enabled && venue.isNightlifeClass) {
        items.push({
          exit: true,
          link: `${nonReactPath}/reports/tally`,
          name: 'Tally',
        })
      }
      if (venue.isDiningClass) {
        if (permissions.legacyReportsAccess.utilization) {
          items.push({
            exit: true,
            link: `${nonReactPath}/reports/utilization`,
            name: 'Utilization',
          })
        }
        if (permissions.legacyReportsAccess.no_show_short_show) {
          items.push({
            exit: true,
            link: `${nonReactPath}/reports/noshow`,
            name: 'No Show / Short Show',
          })
        }
        if (permissions.legacyReportsAccess.wait_times) {
          items.push({
            exit: true,
            link: `${nonReactPath}/reports/wait-times`,
            name: 'Wait Times',
          })
        }
        if (permissions.legacyReportsAccess.turndown_summary) {
          items.push({
            exit: true,
            link: `${nonReactPath}/reports/turndown`,
            name: 'Turndown Summary',
          })
        }
        if (permissions.legacyReportsAccess.turn_analysis) {
          items.push({
            exit: true,
            link: `${nonReactPath}/reports/turnanalysis`,
            name: 'Turn Analysis',
          })
        }
      }
    }
    if (permissions.canViewActuals) {
      adminItems.push({
        exit: true,
        link: `${nonReactPath}/actuals`,
        name: 'Actuals',
      })
    }
    items.push({
      exit: true,
      link: `${nonReactPath}/search/reservations`,
      name: 'Search Reservations',
    })
    if (permissions.canViewReportingFull) {
      if (venueSettings.payments_enabled) {
        items.push({
          exit: true,
          link: `${nonReactPath}/reports/payments`,
          name: 'Search Payments',
        })
      }
    }
    if (permissions.canViewOutgoingEmails) {
      adminItems.push({
        link: `${match.path}/outgoingemails`,
        name: 'Outgoing Emails',
        id: 'outgoingemails',
      })
    }
    if (permissions.canViewReportingFull) {
      adminItems.push({
        exit: true,
        link: `${nonReactPath}/activitylog`,
        name: 'Activity Log',
      })
    }
    if (venueSettings.adwords_reporting) {
      items.push({
        exit: true,
        link: `${nonReactPath}/reports/advertising`,
        name: 'Advertising',
      })
    }
    if (venueSettings.ot_gc_enabled) {
      adminItems.push({
        link: `${match.path}/guestcenter-import`,
        name: 'GuestCenter Channel Connect',
        id: 'guestcenter-import',
      })
    }
    if (venueSettings.is_resy_reservation_manual_import_enabled) {
      adminItems.push({
        link: `${match.path}/resy-import`,
        name: 'Resy Channel Connect',
        id: 'resy-import',
      })
    }

    return [...items, ...adminItems]
  }

  getHomeItems() {
    const { match, venueSettings } = this.props
    const homeSet = [
      {
        id: 'home',
        name: 'Home',
        link: `${match.path}/home`,
      },
    ]
    if (venueSettings.is_reporting_revenue_dashboard_enabled) {
      homeSet.push({
        id: 'revenue',
        name: 'Revenue',
        link: `${match.path}/revenue`,
      })
    }
    return homeSet.length
      ? [
          {
            name: 'Reporting',
            isTitle: true,
          },
          ...homeSet,
        ]
      : []
  }

  render() {
    const { match, venueSettings, permissions } = this.props

    return (
      <ReportingContainer>
        <SubNav items={this.state.items} />
        <ContentContainer>
          <Switch>
            <Route path={`${match.path}/home`} component={ReportingHomepageLayout} />
            {venueSettings.is_reporting_revenue_dashboard_enabled && (
              <Route path={`${match.path}/revenue`} component={RevenueReportLayout} />
            )}
            <Route path={`${match.path}/outgoingemails`} component={permissions.canViewOutgoingEmails ? OutgoingEmails : ErrorNotFound} />
            <Route path={`${match.path}/guestcenter-import`} component={venueSettings.ot_gc_enabled ? GuestCenterImport : ErrorNotFound} />
            <Route
              path={`${match.path}/resy-import`}
              component={venueSettings.is_resy_reservation_manual_import_enabled ? ResyImport : ErrorNotFound}
            />
            <Route path={`${match.path}/embed`} component={LookerReportingLayout} />
            <Route path="*" component={ErrorNotFound} />
          </Switch>
        </ContentContainer>
      </ReportingContainer>
    )
  }
}

const mapStateToProps = state => {
  const { venue } = state.appState

  return {
    venue,
  }
}

export default withRouter(connect(mapStateToProps, null)(Reporting))
