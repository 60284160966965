import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  useNavigationPermissionsContext,
  useStoreSelector,
  useUserContext,
  useVenueContext,
  useVenueSettingsContext,
} from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useReportingLookerNavigationItems } from '@sevenrooms/mgr-core/hooks/useLookerNavigationItems'
import { routes } from '@sevenrooms/routes'
import ThumbnailBlue from '../images/thumbnail_blue.svg'
import ThumbnailGreen from '../images/thumbnail_green.svg'
import ThumbnailGrey from '../images/thumbnail_grey.svg'
import ThumbnailPurple from '../images/thumbnail_purple.svg'
import ThumbnailRed from '../images/thumbnail_red.svg'
import { navigationItemMessages } from '../locales/navigationItemMessages'
import { reportingMessages } from '../locales/reportingMessages'
import type { NavigationDashboardCategoryItem } from '../components/NavigationDashboard'

export function useStaticNavigationItems(): NavigationDashboardCategoryItem[] {
  const { formatMessage } = useLocales()

  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const permissions = useNavigationPermissionsContext()
  const user = useUserContext()
  const nav = useNavigation()

  if (!permissions || !user || !venueSettings || Object.keys(venue).length === 0) {
    return []
  }

  const nonReactPath = `/manager/${venue.urlKey}`
  const userUrlSuffix = permissions.canViewReportingFull ? '' : `/${user.id}`
  const adminItems = []
  const items = []

  if (permissions.canViewReportingFull && permissions.legacyReportsAccess.reservationSummary) {
    items.push({
      id: 'legacy-reservation-summary',
      reload: true,
      to: `${nonReactPath}/reports/summary`,
      title: formatMessage(navigationItemMessages.legacyReservationSummaryTitle),
      description: formatMessage(navigationItemMessages.legacyReservationSummaryDescription),
      thumbnail: ThumbnailGrey,
      items: [],
    })
  }
  if (permissions.legacyReportsAccess.bookedBy) {
    items.push({
      id: 'legacy-booked-by',
      reload: true,
      to: `${nonReactPath}/reports/bookedby${userUrlSuffix}`,
      title: formatMessage(navigationItemMessages.legacyBookedByTitle),
      description: formatMessage(navigationItemMessages.legacyBookedByDescription),
      thumbnail: ThumbnailGrey,
      items: [],
    })
  }
  if (permissions.legacyReportsAccess.sourcedBy) {
    items.push({
      id: 'legacy-source-by',
      reload: true,
      to: `${nonReactPath}/reports/sourceby${userUrlSuffix}`,
      title: formatMessage(navigationItemMessages.legacySourceByTitle),
      description: formatMessage(navigationItemMessages.legacySourceByDescription),
      thumbnail: ThumbnailGrey,
      items: [],
    })
  }
  if (permissions.canViewReportingFull) {
    if (venue.isDiningClass) {
      if (permissions.legacyReportsAccess.noShowShortShow) {
        items.push({
          id: 'legacy-no-show',
          reload: true,
          to: `${nonReactPath}/reports/noshow`,
          title: formatMessage(navigationItemMessages.legacyNoShowTitle),
          description: formatMessage(navigationItemMessages.legacyNoShowDescription),
          thumbnail: ThumbnailGrey,
          items: [],
        })
      }
      if (permissions.legacyReportsAccess.waitTimes) {
        items.push({
          id: 'wait-times',
          reload: true,
          to: `${nonReactPath}/reports/wait-times`,
          title: formatMessage(navigationItemMessages.waitTimesTitle),
          description: formatMessage(navigationItemMessages.waitTimesDescription),
          items: [],
          thumbnail: ThumbnailGrey,
        })
      }
      if (permissions.legacyReportsAccess.turndownSummary) {
        items.push({
          id: 'turndown',
          reload: true,
          to: `${nonReactPath}/reports/turndown`,
          title: formatMessage(navigationItemMessages.turndownTitle),
          description: formatMessage(navigationItemMessages.turndownDescription),
          items: [],
          thumbnail: ThumbnailGrey,
        })
      }
    }
  }
  if (permissions.canViewActuals) {
    adminItems.push({
      id: 'actuals',
      reload: true,
      to: `${nonReactPath}/actuals`,
      title: formatMessage(navigationItemMessages.actualsTitle),
      description: formatMessage(navigationItemMessages.actualsDescription),
      thumbnail: ThumbnailGreen,
      items: [],
    })
  }
  items.push({
    id: 'search-reservations',
    reload: true,
    to: `${nonReactPath}/search/reservations`,
    title: formatMessage(navigationItemMessages.searchReservationsTitle),
    description: formatMessage(navigationItemMessages.searchReservationsDescription),
    items: [],
    thumbnail: ThumbnailGrey,
  })
  if (permissions.canViewReportingFull) {
    if (venueSettings.payments_enabled) {
      items.push({
        id: 'search-payments',
        reload: true,
        to: `${nonReactPath}/reports/payments`,
        title: formatMessage(navigationItemMessages.searchPaymentsTitle),
        description: formatMessage(navigationItemMessages.searchPaymentsDescription),
        items: [],
        thumbnail: ThumbnailGrey,
      })
    }
  }
  if (permissions.canViewOutgoingEmails) {
    adminItems.push({
      id: 'outgoingemails',
      reload: true,
      to: nav.href(routes.manager2.reporting.outgoingemails, {
        params: { venueKey: venue.urlKey },
      }),
      title: formatMessage(navigationItemMessages.outgoingEmailsTitle),
      description: formatMessage(navigationItemMessages.outgoingEmailsDescription),
      items: [],
      thumbnail: ThumbnailBlue,
    })
  }
  if (permissions.canViewReportingFull) {
    adminItems.push({
      id: 'activity-log',
      reload: true,
      to: `${nonReactPath}/activitylog`,
      title: formatMessage(navigationItemMessages.activityLogTitle),
      description: formatMessage(navigationItemMessages.activityLogDescription),
      items: [],
      thumbnail: ThumbnailRed,
    })
  }
  if (venueSettings.adwords_reporting) {
    items.push({
      id: 'adwords-reporting',
      reload: true,
      to: `${nonReactPath}/reports/advertising`,
      title: formatMessage(navigationItemMessages.advertisingTitle),
      description: formatMessage(navigationItemMessages.advertisingDescription),
      items: [],
      thumbnail: ThumbnailGrey,
    })
  }
  if (venueSettings.ot_gc_enabled) {
    adminItems.push({
      id: 'guestcenter-import',
      reload: true,
      to: nav.href(routes.manager2.reporting.guestcenterImport, {
        params: { venueKey: venue.urlKey },
      }),
      title: formatMessage(navigationItemMessages.guestCenterTitle),
      description: formatMessage(navigationItemMessages.guestCenterDescription),
      items: [],
      thumbnail: ThumbnailPurple,
    })
  }

  return [
    {
      id: 'standard',
      title: formatMessage(reportingMessages.reportingCategoryStandard),
      items,
    },
    {
      id: 'administrative',
      title: formatMessage(reportingMessages.reportingCategoryAdministrative),
      items: adminItems,
    },
  ]
}

const MAX_RECENTLY_VIEWED = 3

export function useReportViewHistory() {
  const lookerContextRecentlyViewed = useStoreSelector(state => state?.looker?.reportViewHistory)
  const appContextRecentlyViewed = (useAppContext().userDomain?.reportViewHistory as string[]) ?? []

  return lookerContextRecentlyViewed
    .concat(appContextRecentlyViewed.filter(x => !lookerContextRecentlyViewed.includes(x)))
    .slice(0, MAX_RECENTLY_VIEWED)
}

export function useNavigationDashboardItems(): NavigationDashboardCategoryItem[] {
  const { formatMessage } = useLocales()

  const lookerItems = useReportingLookerNavigationItems()
  const staticItems = useStaticNavigationItems()
  const recentlyViewed = useReportViewHistory()

  const allItems = lookerItems.concat(staticItems)

  const recentlyViewedItems = []
  const popularItems = []
  const remainingItems = []
  for (const item of allItems.flatMap(x => x.items)) {
    const recentlyViewedSortOrder = recentlyViewed.findIndex(x => item.to.endsWith(x))
    if (recentlyViewedSortOrder >= 0) {
      recentlyViewedItems.push({
        ...item,
        recentlyViewedSortOrder,
        badge: formatMessage(reportingMessages.badgeRecentlyViewed, {
          icon: <Icon name="VMSWeb-eye" size="sm" />,
        }),
      })
    } else if (item.popularSortOrder) {
      popularItems.push({
        ...item,
        popularSortOrder: item.popularSortOrder,
        badge: formatMessage(reportingMessages.badgePopular, {
          icon: <Icon name="VMSWeb-trending-up" size="sm" />,
        }),
      })
    } else {
      remainingItems.push(item)
    }
  }

  const topItems = [
    ...recentlyViewedItems.sort((a, b) => a.recentlyViewedSortOrder - b.recentlyViewedSortOrder),
    ...popularItems.sort((a, b) => a.popularSortOrder - b.popularSortOrder),
  ]

  return [
    {
      id: 'highlighted-reports',
      title: formatMessage(reportingMessages.headerHighlightedReports),
      hideTitle: true,
      items: topItems,
    },
    {
      id: 'all-reports',
      title: formatMessage(reportingMessages.headerAllReports),
      items: remainingItems,
    },
  ]
}
