import type { RevenueTypeKey } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Flex, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { reportingMessages } from '../../locales'
import { formatBold, formatCurrency, getRevenueTypeInfo } from '../../utils'
import { StatusBadge } from '../Status'

interface RevenueOpportunityCardProps {
  revenueType: RevenueTypeKey
  missedBenchmark: number
}

export function RevenueOpportunityCard({ revenueType, missedBenchmark }: RevenueOpportunityCardProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()

  const { title, aboutText, mainLink, outPackageLink, image, locked } = getRevenueTypeInfo(revenueType, venue, venueSettings)

  const formattedTitle = title ? formatMessage(title) : ''

  const actionLink = locked ? outPackageLink : mainLink
  const actionText = locked
    ? formatMessage(reportingMessages.requestButtonText)
    : formatMessage(reportingMessages.tryButtonText, { feature: formattedTitle })

  const buttonDataTest = `opportunity-card-action-button-${revenueType}`

  return (
    <Flex
      backgroundColor="primaryBackground"
      width="510px"
      borderWidth="s"
      borderColor="dividerLines"
      borderTopColor="attentionNew"
      borderRadius="s"
      flexShrink="0"
      data-test="opportunity-card"
    >
      <VStack>
        <Box pt="m" pb="m" pl="lm" pr="lm" borderWidth="s" borderBottomColor="dividerLines" minHeight="110px">
          <HStack>
            <Box mt="sm" mb="xs" mr="auto">
              <Header type="h2" data-test="opportunity-card-header">
                {locked && (
                  <Box display="inline" mr="xs">
                    <Icon name="VMSWeb-lock" color="secondaryFont" />
                  </Box>
                )}
                {formattedTitle}
              </Header>
            </Box>
            <StatusBadge statusType="OPPORTUNITY" />
          </HStack>
          <Box>
            <Text>
              {formatMessage(reportingMessages.regionComparison, {
                strong: (chunks: string[]) => formatBold(chunks),
                amount: formatCurrency(venue, missedBenchmark),
                feature: formattedTitle,
              })}
            </Text>
          </Box>
        </Box>
        <HStack pt="m" pb="m" pl="lm" pr="lm" minHeight="173px">
          <VStack>
            <Header type="h3">{formatMessage(reportingMessages.aboutThisFeature)}</Header>
            <Box mt="xs" mb="auto">
              <SecondaryText data-test="opportunity-description" type="p">
                {aboutText ? formatMessage(aboutText) : ''}
              </SecondaryText>
            </Box>
            <Box>
              <Button data-test={buttonDataTest} isExternal href={actionLink || ''} variant="primary" size="s">
                {actionText}
              </Button>
              {locked && (
                <>
                  <Box display="inline" mr="xs" ml="xs">
                    <Icon name="VMSWeb-lock" color="secondaryFont" />
                  </Box>
                  <SecondaryText fontSize="s">{formatMessage(reportingMessages.requiresPackageChange)}</SecondaryText>
                </>
              )}
            </Box>
          </VStack>
          <Box ml="l">
            <img alt="" width="136px" height="136px" src={image} />
          </Box>
        </HStack>
      </VStack>
    </Flex>
  )
}
