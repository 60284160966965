import { Box } from '@sevenrooms/react-components/components/Box'
import { CircularProgress } from '@sevenrooms/react-components/components/CircularProgress'

export function AvailabilityCalendarLoader({ minHeight }: { minHeight?: number }) {
  return (
    <Box display="flex" minHeight={minHeight} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  )
}
