import { useMemo, useRef } from 'react'
import { useGetAudienceHierarchyQuery } from '@sevenrooms/core/api'
import type { AvailabilityDebuggerReason, Venue, AudienceHierarchy } from '@sevenrooms/core/domain'
import type { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { AccessRulesMatchCategory } from './ContentCategory/AccessRulesMatchCategory'
import { AccessRulesTargetingCategory } from './ContentCategory/AccessRulesTargetingCategory'
import { CombosMatchCategory } from './ContentCategory/CombosMatchCategory'
import { CombosTargetingCategory } from './ContentCategory/CombosTargetingCategory'
import { InactiveCategory } from './ContentCategory/InactiveCategory'
import { ShiftsCategory } from './ContentCategory/ShiftsCategory'
import { TablesMatchCategory } from './ContentCategory/TablesMatchCategory'
import { TablesTargetingCategory } from './ContentCategory/TablesTargetingCategory'
import { VenueCategory } from './ContentCategory/VenueCategory'

export interface ModalContentProps {
  allReasons: AvailabilityDebuggerReason[]
  venue: Venue
  date: DateOnly
  time: TimeOnly
  covers: number
  duration: number
}

type GroupByCategory = Record<string, AvailabilityDebuggerReason[]>

function initializeGroupByCategory(): GroupByCategory {
  return {
    VENUE: [],
    SHIFTS: [],
    ACCESS_RULE_MATCH: [],
    ACCESS_RULE_TARGETING: [],
    TABLES_MATCH: [],
    TABLES_TARGETING: [],
    COMBO_MATCH: [],
    COMBO_TARGETING: [],
    INACTIVE: [],
  }
}

export function ReasonsModalContent({ allReasons, venue, date, time, covers, duration }: ModalContentProps) {
  const firstCategoryRender = useRef(true)
  const { isFetching, data: audienceHierarchy } = useGetAudienceHierarchyQuery({ venueId: venue.id })

  const audiences = useMemo(() => {
    const result: Record<string, string> = {}

    if (isFetching || !audienceHierarchy) {
      return null
    }

    function recurse(audienceHierarchies: AudienceHierarchy[]) {
      for (const item of audienceHierarchies) {
        if (item.name && item.value) {
          result[item.value] = item.name
        }
        if (item.children && item.children.length > 0) {
          recurse(item.children)
        }
      }
    }

    recurse(audienceHierarchy)
    return result
  }, [audienceHierarchy, isFetching])

  const groupByCategories = useMemo(() => {
    if (allReasons.length < 1) {
      return null
    }

    const filteredAllReasons = filterByFullStop(allReasons)

    const gbc: GroupByCategory = initializeGroupByCategory()
    filteredAllReasons?.forEach(r => {
      const cat = r.category
      if (cat in gbc) {
        gbc[cat] = [...(gbc[cat] ?? []), r]
      }
    })

    return gbc
  }, [allReasons])

  // Return any full stop reasons present in the grouping. Some full stop reasons return for every table,
  // we want to capture all tables and we de-dupe when necessary during content rendering
  function filterByFullStop(allReasons: AvailabilityDebuggerReason[]): AvailabilityDebuggerReason[] {
    const fullStopReasons = [
      'VENUE_CLOSED',
      'NO_MATCHING_SHIFTS',
      'NO_ACCESS_RULES_FOR_SHIFT',
      'OVER_MAX_SHIFT_COVERS',
      'NO_INVENTORY',
      'NO_PACING_COVERS_REMAINING',
      'NO_INVENTORY_COVERS_REMAINING',
      'NO_INVENTORY_RESERVATIONS_REMAINING',
      'CUTOFF_PASSED',
      'TAGS_MISMATCH',
    ]

    let hasFullStopReason = false

    const filteredReasons = allReasons.filter(reason => {
      if (reason.reason && fullStopReasons.includes(reason.reason)) {
        hasFullStopReason = true
        return true
      }
      return false
    })

    return hasFullStopReason ? filteredReasons : allReasons
  }

  if (!groupByCategories || !audiences) {
    return <></>
  }

  return (
    <Box data-test="reasons-modal-content" sx={{ overflow: 'scroll', paddingTop: '2px' }}>
      {Object.entries(groupByCategories)
        .filter(([, reasons]: [string, AvailabilityDebuggerReason[]]) => reasons.length > 0)
        .map(([category, reasons]) => {
          const key = `${category}-${reasons[0]?.reason}-${reasons[0]?.text}`
          const isFirst = firstCategoryRender.current
          if (isFirst) {
            firstCategoryRender.current = false
          }

          switch (category) {
            case 'INACTIVE':
              return <InactiveCategory key={key} reasons={reasons} venue={venue} time={time} />
            case 'VENUE':
              return <VenueCategory key={key} venue={venue} reasons={reasons} />
            case 'SHIFTS':
              return <ShiftsCategory key={key} reasons={reasons} venue={venue} time={time} date={date} covers={covers} />
            case 'ACCESS_RULE_MATCH':
              return (
                <AccessRulesMatchCategory
                  key={key}
                  defaultExpanded={isFirst}
                  reasons={reasons}
                  venue={venue}
                  covers={covers}
                  date={date}
                  time={time}
                  audiences={audiences}
                />
              )
            case 'ACCESS_RULE_TARGETING':
              return (
                <AccessRulesTargetingCategory
                  key={key}
                  defaultExpanded={isFirst}
                  reasons={reasons}
                  venue={venue}
                  date={date}
                  time={time}
                  covers={covers}
                  duration={duration}
                />
              )
            case 'TABLES_MATCH':
              return <TablesMatchCategory key={key} defaultExpanded={isFirst} reasons={reasons} time={time} date={date} venue={venue} />
            case 'TABLES_TARGETING':
              return <TablesTargetingCategory key={key} defaultExpanded={isFirst} reasons={reasons} venue={venue} />
            case 'COMBO_MATCH':
              return <CombosMatchCategory key={key} defaultExpanded={isFirst} reasons={reasons} time={time} date={date} venue={venue} />
            case 'COMBO_TARGETING':
              return <CombosTargetingCategory key={key} defaultExpanded={isFirst} reasons={reasons} venue={venue} />
            default:
              return <></>
          }
        })}
    </Box>
  )
}
